<template>
  <div class=""></div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    this.getToken();
  },
  mounted() {},
  methods: {
    getToken() {
      let query = this.$route.query;
      let params = {
        ...query,
      };
      this.$http.get("/login/autoLogin", { params }).then((res) => {
        if (res.data.code === 200) {
          let languageList = ["VI", "ID", "EN", "TH", "ZH"];
          let lang = languageList.includes(query.language)
            ? query.language
            : "EN";
          localStorage.setItem("locale", lang);
          this.$store.state.lang = lang;
          this.$bus.$emit("locale", lang);
          this.$i18n.locale = lang;
          let token = res.data.data.token;
          localStorage.setItem("token", JSON.stringify(token));
          this.selectServe = true;
          this.$router.push("/consume");
          setTimeout(() => {
            this.showLoading = false;
          }, 200);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
